import { ComponentStyleConfig } from '@chakra-ui/react'

export const Text: ComponentStyleConfig = {
  baseStyle: {
    color: 'slate.600',
    _dark: {
      color: 'white',
    },
  },
}
