import { ComponentStyleConfig } from '@chakra-ui/react'

export const IconButton: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s',
    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    _focus: {
      boxShadow: 'outline',
    },
  },
  sizes: {
    sm: {
      width: '8',
      height: '8',
      fontSize: 'md',
    },
    md: {
      width: '10',
      height: '10',
      fontSize: 'lg',
    },
    lg: {
      width: '12',
      height: '12',
      fontSize: 'xl',
    },
  },
  variants: {
    solid: {
      color: 'white',
      bg: 'primary.600',
      _hover: {
        bg: 'primaryLight',
      },
      _active: {
        bg: 'primaryDark',
      },
      _disabled: {
        bg: 'primary',
        opacity: 0.4,
      },
    },
    outline: {
      color: 'primary',
      borderColor: 'primary',
      _hover: {
        bg: 'primaryLight',
      },
      _active: {
        bg: 'primaryDark',
      },
      _disabled: {
        bg: 'transparent',
        opacity: 0.4,
      },
    },
  },
}
