/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useCopyToClipboard } from "react-use";
import { Box, Center, Flex, Stack, Text } from "@chakra-ui/react";
import usePwa from "@hooks/action/usePwa";
import { Icon } from "@iconify/react";

const Prompt = () => {
  const { browser, platform, showPrompt: isPwaShow } = usePwa();
  const [showPrompt, setShowPrompt] = useState(false);
  useEffect(() => {
    setShowPrompt(!!isPwaShow);
  }, [isPwaShow]);

  if (!showPrompt) {
    return <></>;
  }

  return (
    <Box w="100vw" h="100vh" bg="rgba(60, 60, 60,0.5)" position="fixed" borderWidth={1} borderColor={"black"} zIndex="popover">
      <Flex justify="center" align="center">
        {platform === "ios" ? (
          <Box position="fixed" bottom="2" px="3" w="full">
            <Box px="4" py="4" borderRadius="xl" bg={"white"} m={2}>
              {browser === "safari" ? <SafariPrompt setShowPrompt={setShowPrompt} /> : <GoogleChromePrompt setShowPrompt={setShowPrompt} />}
            </Box>
          </Box>
        ) : (
          <Box position="fixed" bottom={platform === "android" ? "2" : "16"} px="3" w="full">
            <Box bg="white" px="4" py="4" borderRadius="xl" borderWidth={1} m={4}>
              <AndroidPrompt setShowPrompt={setShowPrompt} />
            </Box>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

const SafariPrompt = ({ setShowPrompt }: any) => {
  return (
    <>
      <Box>
        <Flex justify="space-between">
          <Text fontSize="lg" transform="initial" fontWeight="semibold" color="grey-700">
            Add to Home Screen
          </Text>
          <Box onClick={() => setShowPrompt(false)}>
            <Text color="iphone-blue">Cancel</Text>
          </Box>
        </Flex>
      </Box>
      <Box px="2" py="7">
        <Text fontSize="xs" transform="initial" fontWeight="normal" color="grey-600">
          This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.
        </Text>
      </Box>
      <Stack gap={2}>
        <Flex align="start" gap={3}>
          <Center w="6" h="6" overflowX="hidden" overflowY="hidden" borderRadius="md" bg="gray.200">
            <Icon icon="ion:share-outline" color="iphone-blue" />
          </Center>
          <Text fontSize="sm" transform="initial" fontWeight="normal" color="grey-600">
            1) Press the 'Share' button on the menu bar below.
          </Text>
        </Flex>
        <Flex align="center" gap={3}>
          <Center w="6" h="6" overflowX="hidden" overflowY="hidden" borderRadius="md" bg="gray.200">
            <Icon icon="ion:add-outline" color="black" />
          </Center>
          <Text fontSize="sm" transform="initial" fontWeight="normal" color="grey-600">
            2) Press 'Add to Home Screen'.
          </Text>
        </Flex>
      </Stack>
    </>
  );
};

const GoogleChromePrompt = ({ setShowPrompt }: any) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyToClipboardClick = (text: string) => {
    toast.success(
      <Text fontSize="xs" fontWeight="semibold" fontFamily="iphone">
        Link add to your Clipboard.
      </Text>
    );
    copyToClipboard(text);
  };
  return (
    <Box>
      <Box>
        <Flex justify="space-between">
          <Text fontSize="lg" transform="initial" fontWeight="semibold" color="grey-700">
            Add to Home Screen
          </Text>
          <Box onClick={() => setShowPrompt(false)}>
            <Text color="iphone-blue">Cancel</Text>
          </Box>
        </Flex>
      </Box>
      <Box px="2" py="7">
        <Text fontSize="xs" transform="initial" fontWeight="normal" color="gray.600">
          Please open this url in Safari browser to add this Application in your Home screen.
        </Text>
      </Box>
      <Box bg="purple.100" borderRadius="md" px="2" py="2">
        <Flex justify="space-between" align="center">
          <Box maxW="75vw" overflowX="hidden">
            <Text color="purple.500" fontWeight="semibold" transform="lowercase" whiteSpace="nowrap">
              {window.location.href}
            </Text>
          </Box>
          <Box ml="5" onClick={() => copyToClipboardClick(window.location.href)}>
            <Icon icon="ph:copy-simple-fill" color="purple.500" fontSize={24} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

const AndroidPrompt = ({ setShowPrompt }: any) => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e: any) => {
      if (e) {
        setShowPrompt(true);
      }
      // Prevent Chrome <= 67 from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
    });
  }, []);

  function handleBannerClick() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          // eslint-disable-next-line no-console
          console.log("User installed the PWA");
        } else {
          // eslint-disable-next-line no-console
          console.log("User dismissed the PWA prompt");
        }
        setDeferredPrompt(null); // reset the deferredPrompt variable
      });
    }
    setShowPrompt(false);
  }

  return (
    <Box>
      <Box>
        <Flex justify="space-between">
          <Text fontSize="lg" transform="initial" fontWeight="semibold" color="grey-700">
            Add to Home Screen
          </Text>
          <Box onClick={() => setShowPrompt(false)}>
            <Text color="red-500">Cancel</Text>
          </Box>
        </Flex>
      </Box>
      <Box px="2" py="7">
        <Text fontSize="xs" transform="initial" fontWeight="normal" color="grey-600">
          Click on 'Add to Home Screen' to install this application.
        </Text>
      </Box>
      <Box>
        <Flex>
          <Box borderWidth="0.5" borderColor="red-400" shadow="normal" bg="white" borderRadius="lg" mr="3">
            <img height={50} width={60} src="/assets/logo/apple-touch-icon.png" />
          </Box>
          <Box>
            <Text fontSize="base" fontWeight="bold">
              HM App
            </Text>
            <Box my="2">
              <Text fontSize="xs" fontWeight="normal">
                {window.location.host}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box shadow="md" mt="5" py="2.5" borderRadius="md" bg="red.500" onClick={handleBannerClick}>
          <Text color="white" fontSize="sm" fontWeight="semibold" textAlign={"center"}>
            Add to Home Screen
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Prompt);
