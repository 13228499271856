import React, { useEffect, useState } from "react";
import { useLoginUser } from "../hooks/api/useUserApi";
import { Box, Center, Divider, Flex, IconButton, Text, useColorModeValue } from "@chakra-ui/react";
import { Loader } from "../pages/Navigation";
import { version, name } from "../../package.json";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Prompt from "@components/molecules/Prompt";
import WatermarkWrapper from "@features/app-security/WatermarkWrapper";
import { useAppStore } from "@store/AppStore";

const ALLOWED_PATH_REGEX = ["case-history"];

const AppWrapper = React.memo(({ children }: { children: React.ReactNode }) => {
  const bg = useColorModeValue("gray.100", "slate.800");
  const { isLoading } = useLoginUser();
  const email = useAppStore(state => state.loginUser.email);

  const [isActive, setIsActive] = useState(false);

  // useEffect(() => {
  //   if (ALLOWED_PATH_REGEX.some(path => window.location.pathname.includes(path))) {
  //     setIsActive(true);
  //   } else {
  //     setIsActive(false);
  //   }
  // }, [window.location.pathname]);

  return isLoading ? (
    <Center height="100vh">
      <Loader />
    </Center>
  ) : (
    <WatermarkWrapper text={email || ""}>
      <Center>
        <Box maxH={"95vh"} maxW={"550px"} w={"full"} bg={bg}>
          <Box pb={9}>{children}</Box>
        </Box>
        <Footer />
        {/* {isActive && (
        <IconButton
          pos={"fixed"}
          right={14}
          bottom={16}
          bg="red.100"
          aria-label="Search database"
          color={"red"}
          icon={<Icon icon={"subway:add"} fontSize={40} />}
          borderRadius={"full"}
          size={"2xl"}
          p={2}
          onClick={() => (window.location.pathname = "/create-case")}
          className="blob"
        />
      )} */}
      </Center>
    </WatermarkWrapper>
  );
});

export const Footer = () => {
  const bg = useColorModeValue("white", "slate.800");
  return (
    <Box bg={bg} pos={"fixed"} bottom={0} w={"full"}>
      <Divider />
      <Center py={2}>
        <Flex justify={"center"}>
          <Text fontSize={"2xs"} textTransform={"capitalize"}>
            {name} version
          </Text>
          <Text fontSize={"xs"} textTransform={"capitalize"} fontWeight={"semibold"} ml={2} mt={-0.5}>
            v.{version}! ✨
          </Text>
        </Flex>
      </Center>
    </Box>
  );
};

export default AppWrapper;
