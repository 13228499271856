// OfflineHandler.tsx
import Offline from "@components/molecules/Offline";
import { useEffect, useState } from "react";

const OfflineHandler = ({ children }: any) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleOnlineStatus() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  // Render your app normally
  return <>{isOnline ? children : <Offline />}</>;
};

export default OfflineHandler;
