import dayjs from "dayjs";
import { useEffect, useState } from "react";

type TPlatform = "ios" | "android" | "web" | "";
type TBrowser = "safari" | "chrome" | "";

const ua = window.navigator.userAgent;

const getBrowser = (platform: TPlatform): TBrowser => {
  const webkit = !!ua.match(/WebKit/i);
  if (platform === "ios" && webkit && !ua.match(/CriOS/i)) {
    return "safari";
  } else {
    return "chrome";
  }
};

const getPlatform = (): TPlatform => {
  // IPhone
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  const isIOS = isIPad || isIPhone;
  if (isIOS) {
    return "ios";
  }

  // Android
  const isAndroid = !!ua.match(/Android/i);
  if (isAndroid) {
    return "android";
  }

  // Web
  return "web";
};

const isPwaInstall = (): boolean => {
  const { standalone }: any = navigator;
  return !!standalone;
};

const getDurationBetweenPreviousPrompt = () => {
  const today = dayjs().toDate();
  const lastPrompt = dayjs(localStorage.getItem("installPrompt"));
  const days = dayjs(today).diff(lastPrompt, "days");
  return { today, days };
};

const getPwaData = (): { showPrompt?: boolean; browser?: TBrowser; platform?: TPlatform } => {
  // 1. Check PWA install status
  if (isPwaInstall()) return {};

  // 2. Find-out the platform
  const platform = getPlatform();

  // 3. Find-out the browser
  const browser = getBrowser(platform);

  // 4. Get previous prompt timeline
  const { days, today } = getDurationBetweenPreviousPrompt();

  // 5. Check on prompt visibility

  if (isNaN(days)) {
    localStorage.setItem("installPrompt", today.toString());
    return { showPrompt: true, browser, platform };
  }

  // 6. Setting the localStorage
  if (days > 1 && "localStorage" in window) {
    localStorage.setItem("installPrompt", today.toString());
    return { showPrompt: true, browser, platform };
  }

  return { showPrompt: false, browser, platform };
};

const usePwa = () => {
  const [pwa, setPwa] = useState<{
    showPrompt?: boolean;
    browser?: TBrowser;
    platform?: TPlatform;
  }>({});

  useEffect(() => {
    setPwa(getPwaData());
    // eslint-disable-next-line no-console
    return () => console.log("CLEANUP INSTALL PROMPT", pwa);
  }, []);

  return pwa;
};

export default usePwa;
