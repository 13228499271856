import * as Sentry from "@sentry/react";
import { version } from "../package.json";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN, // Use your Sentry DSN here
  release: `business-app@${version}`,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), new Sentry.BrowserProfilingIntegration()],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  autoSessionTracking: true,
  tracePropagationTargets: [/^https:\/\/business\.redos\.red/, "https://business.redos.app"],
  tracesSampleRate: 1.0, // Adjust based on your needs
});
