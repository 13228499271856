// import Logo from '@assets/svg/red-health.svg'

import { Text } from "@chakra-ui/react";

export default function Offline() {
  return (
    <center style={{ height: "100vh", width: "100vw" }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: 16,
        }}
      >
        {/* <img src={Logo} /> */}
        <div style={{ margin: "30px 0px" }}>
          <div style={{ margin: "20px 10px" }}>
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZjAwMDAiIHN0cm9rZS13aWR0aD0iMi4yNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS11bnBsdWciPjxwYXRoIGQ9Im0xOSA1IDMtMyIvPjxwYXRoIGQ9Im0yIDIyIDMtMyIvPjxwYXRoIGQ9Ik02LjMgMjAuM2EyLjQgMi40IDAgMCAwIDMuNCAwTDEyIDE4bC02LTYtMi4zIDIuM2EyLjQgMi40IDAgMCAwIDAgMy40WiIvPjxwYXRoIGQ9Ik03LjUgMTMuNSAxMCAxMSIvPjxwYXRoIGQ9Ik0xMC41IDE2LjUgMTMgMTQiLz48cGF0aCBkPSJtMTIgNiA2IDYgMi4zLTIuM2EyLjQgMi40IDAgMCAwIDAtMy40bC0yLjYtMi42YTIuNCAyLjQgMCAwIDAtMy40IDBaIi8+PC9zdmc+" />
          </div>
          <Text fontSize="sm" fontWeight="semibold">
            Please check your internet connection, and try again.
          </Text>
        </div>
        <button
          onClick={() => window.location.reload()}
          style={{
            padding: "6px 20px",
            backgroundColor: "#e3f2fd",
            color: "#1565c0",
            fontWeight: "800",
            fontSize: 12,
            border: "0px",
            borderRadius: "100px",
            marginTop: 20,
          }}
        >
          Click here to refresh
        </button>
      </div>
    </center>
  );
}
