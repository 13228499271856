import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./animation.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./styles/theme.ts";
import RedQueryClientProvider from "./scripts/reactQuery.tsx";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "../package.json";
import "./sentry.ts";
import { redPushNotification } from "lib/red-hooks.ts";

// Sentry.init({
//   dsn: "https://8ec8913fdac7a9cd978db4c8dee72b50@o4505289618554880.ingest.us.sentry.io/4507017653387264",
//   integrations: [
//     // See docs for support of different versions of variation of react router
//     // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//     }),
//     Sentry.replayIntegration(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: process..VITE_SENTRY_DSN,
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ["localhost", /^https:\/\/business\.redos\.red\//],
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   tracesSampleRate: 1.0,
//   enabled: true,
//   enableTracing: true,
// });

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [/^https:\/\/business\.redos\.red\//],
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
//   enabled: !!import.meta.env.VITE_SENTRY_DSN,
// });

// Sentry.init({
//   dsn: '',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/business\.redos\.red\//],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   release: `business-app-${version}`, // Helps you identify issues introduced in new releases
//   integrations: [
//     new BrowserTracing({
//       // Specifies which URLs transactions should be created for.
//       tracePropagationTargets: ["localhost", "https://business.redos.red"],

//       // Additional options for more detailed tracing
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(history), // If you're using React Router v6
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//       // Additional configurations for replays
//     }),
//     // Automatically deduplicate consecutive, repeating errors
//     new Integrations.Dedupe(),
//   ],
//   // Adjust sample rates according to your needs for balance between detail and volume
//   tracesSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
//   replaysSessionSampleRate: 1.0,
//   enabled: true, // Consider dynamically enabling/disabling based on environment
//   beforeSend(event, hint) {
//     // Here you can modify the event or filter out events before they are sent
//     console.log(event, hint);

//     // Example: Adding extra data to every event
//     event.extra = { ...event.extra, customData: "my custom data" };

//     // Conditional checks to modify or discard events
//     if (event.level === 'error') {
//       // Add more context or modify the event details
//     }

//     return event;
//   },
//   // Enhanced error data through attachments (e.g., log files)
//   // Note: Attachments are only available in certain Sentry plans
//   attachments: [{
//     filename: 'log.txt',
//     data: 'Log data here', // You could dynamically attach log data
//     contentType: 'text/plain',
//   }],
//   // Customizing the scope allows for enriched event data
//   beforeBreadcrumb(breadcrumb, hint) {
//     // Modify or filter breadcrumbs
//     return breadcrumb.category === 'ui.click' ? null : breadcrumb; // Example: Ignore UI clicks
//   },
// });

// import clevertap from "clevertap-web-sdk";

// const CLEVERTAP_ACCOUNT_ID = import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID;

// Register the service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/clevertap_sw.js")
      .then(registration => {
        console.log("Service Worker registered with scope:", registration.scope);
        redPushNotification.requestPermission();
      })
      .catch(error => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RedQueryClientProvider>
      <ChakraProvider theme={theme}>
        <>
          <ToastContainer />
          <Toaster position="top-center" reverseOrder={false} />
          <App />
        </>
      </ChakraProvider>
    </RedQueryClientProvider>
  </React.StrictMode>
);
