import { extendTheme } from '@chakra-ui/react'
import { Modal } from './chakraComponents/modal'
import { Menu } from './chakraComponents/menu'
import { Button } from './chakraComponents/button'
import { Input } from './chakraComponents/input'
import { Link } from './chakraComponents/link'
import { Tabs } from './chakraComponents/tabs'
import { Drawer } from './chakraComponents/drawer'
import { Heading } from './chakraComponents/heading'
import { Text } from './chakraComponents/text'
import { Select } from './chakraComponents/select'
import { Tooltip } from './chakraComponents/tooltip'
import { IconButton } from './chakraComponents/iconButton'

const THEME_A = {
  primary: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },
  secondary: {
    50: '#FFF5F7',
    100: '#FED7E2',
    200: '#FBB6CE',
    300: '#F687B3',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280',
    700: '#97266D',
    800: '#702459',
    900: '#521B41',
  },
  success: '#71dd37',
  error: {
    light: '#f43e60',
    dark: '#f12a4f',
  },
  info: '#03c3ec',
  warning: '#ffab00',
  slate: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
    950: '#020617',
  },
}

export const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: true,
  fonts: {
    body: 'inter, sans-serif',
    heading: 'inter, sans-serif',
    mono: 'inter, sans-serif',
  },
  colors: THEME_A,
  components: {
    Button,
    Drawer,
    Heading,
    Menu,
    Modal,
    Link,
    Input,
    Tabs,
    Text,
    Tooltip,
    Select,
    IconButton,
  },
})
